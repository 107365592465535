<div style="text-align: justify;">
    <h1 class="m-5 text-center">Eventi</h1>
    <hr>
    <h2 class="m-5">Hire us!</h2>
    <p class="m-5">Sei un promoter e vuoi il rombo delle nostre cornamuse al tuo evento? Sei un privato e vuoi portare un po' di Scozia nella tua cerimonia? La nostra musica ha solcato i palchi dei festival celtici più importanti d'Italia, ma ha anche animato eventi come feste private, cerimonie e sagre.</p>
    <p class="m-5">Qualunque sia il tuo evento, <a [routerLink]="['../contacts']">contattaci</a>: decideremo insieme la soluzione migliore per te tra diverse opzioni tra cui il singolo piper, l’ensemble ridotto e l’intera pipe band.</p>
    <hr>
    <h2 class="m-5">Prossimi eventi</h2>
    <div class="text-center">
        <div *ngIf="loading" class="spinner-border mb-5" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <!--Events list-->

    <div *ngFor="let event of upcoming" class="card m-5">
        <div class="row g-0">
            <!--se non è stata creata la data, c'è un elemento data/ora-->
            <div *ngIf="event.start.date == undefined" class="card-date col-md-2 text-center d-flex justify-content-center align-items-center fs-3">
                {{formatDate(separateDateAndTime(event.start.dateTime)['date']).toUpperCase()}}<br>ore {{formatTime((event.start.dateTime))}}
            </div>

            <!--altrimenti c'è un elemento solo data-->
            <div *ngIf="event.start.dateTime == undefined" class="card-date  col-md-3 d-flex justify-content-center align-items-center fs-3">
                {{formatDate(event.start.date).toUpperCase()}}
            </div>

            <div class="col-md-10">
                <div class="card-header d-flex align-items-center">
                    <h5 class="card-title">{{event.summary}}</h5>
                </div>
                <div class="card-body">
                    <p *ngIf="event.description != undefined" class="card-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                        </svg>
                        &nbsp;{{event.description}}</p>
                </div>
                <div class="card-footer">
                    <p *ngIf="event.location != undefined" class="card-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                        </svg>
                        &nbsp;<a href="{{gMapsLink(event.location)}}" target="_blank">{{event.location}}</a></p>
                </div>
            </div>
        </div>
    </div>
</div>