import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = 'ng-bootstrap';
  year: number = new Date().getFullYear();
  isLandingPage: boolean = true;
  loading: boolean = true;
  
  constructor(private router: Router) {
    
  }
  ngOnInit(): void {
    window.addEventListener('load', () => this.onPageLoad());
  }

  onPageLoad(): void {
    setTimeout(() => {
      const loader = document.getElementById('loader');
      if (loader) {
        loader.classList.add('fade-out');
      }
      // Ritarda la rimozione per consentire il completamento del fade-out
      setTimeout(() => this.loading = false, 500);
    }, 1000); // Simula un breve caricamento
  }

  toggleMenu() {
    const navButton = document.querySelector('#navButton') as HTMLButtonElement;
    const navbar = document.querySelector('#navbar') as HTMLDivElement;
    if(navbar.getAttribute('class')!.includes("show")) {
      navbar.setAttribute("class", navbar.getAttribute('class')?.replace("show", "")!);
    }
  }


    
}
