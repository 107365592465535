import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { News } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  sharedSingleNews!: News;
  activeLink = '';

  constructor(
    private router: Router
  ) { }

  setSharedSingleNews(news: News) {
    this.sharedSingleNews = news;
  }

  getSharedSingleNews() {
    return this.sharedSingleNews;
  }

  updateActiveLink() {
    this.activeLink = this.router.url.replace('/', '');
  }

  public navigate(dest: string) {
    this.activeLink = dest;
    this.router.navigate([dest]);
    this.triggerCloseButtonClick();
    this.router.events.subscribe(
      () => {
        this.activeLink = this.router.url.replace('/', '');
      }
    )
  }

  triggerCloseButtonClick(): void {
    const closeButton = document.querySelector<HTMLButtonElement>('.btn-close');
    if (closeButton) {
        closeButton.click();
    } else {
        console.warn("Bottone di chiusura non trovato.");
    }
  } 
}
