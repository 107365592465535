<h1 class="m-5 text-center">News</h1>

<p *ngIf="news.length == 0" class="m-5"><b>Stay tuned!</b> Non ci sono ancora articoli, ma presto arriveranno tante belle novità!</p>

<div class="album py-5">
  <div class="container">
    <div class="row">
        <div *ngFor="let element of news" class="col-md-4">
            <div class="card border-0 rounded-0 mb-4 box-shadow shadow">
              <img
                class="card-img rounded-0"
                data-src="holder.js/100px225?theme=thumb&bg=55595c&fg=eceeef&text=Thumbnail"
                alt="Card image cap"
                src="{{element.imageLinkLowRes}}"
              />
              <div class="card-body">
                <h5 class="card-title">{{element.title}}</h5>
                <p class="card-text">
                  {{element.contentPreview}}
                </p>
                
                <small *ngIf="element.tags.length != 0" class="d-flex flex-wrap align-items-center">
                  <span *ngFor="let tag of element.tags"><span class="badge rounded-pill text-bg-secondary">#{{tag}}</span>&nbsp;&nbsp;</span>
                </small>
              </div>
              <div class="card-footer d-flex justify-content-between align-items-center">
                <div class="btn-group">
                  <button type="button" class="btn btn-sm btn-secondary" [routerLink]="['/news', element.id]" (click)="setSharedSingleNews(element)">
                    Vai all'articolo &raquo;
                  </button>
                </div>
                <small class="text-muted">{{element.date}}<br>{{element.author}}</small>
              </div>
            </div>
          </div>
    </div>
  </div>
</div>
