import { News } from "src/environments/environment";

export const news: News[] = [
    {
        id: "20241104",
        title: "Abbiamo un nuovo sito!",
        author: "Lorenzo Rossi",
        date: "4 novembre 2024",
        contentPreview: "Finalmente siamo online con il nuovo sito! ...",
        paragraphs: [
            "Finalmente siamo online con il nuovo sito! E ve lo diciamo usando come scusa questa bellissima nostra foto del 2022 al Claddagh Fest di Ameno (NO).",
            "La Home mostra un'anteprima dei contenuti che si possono trovare nelle altre pagine e un breve video che ci ritrae a Celtica, un importante festival di arte e cultura celtica della Valle d'Aosta. ",
            "Nella sezione \"Banda\" potrete dare un nome ed un cognome ai volti che vedete gonfiarsi e sbuffare arte incessantemente durante i nostri servizi, e anche conoscere la storia della nascita della nostra banda. ",
            "La \"Galleria\" raccoglierà le foto ed i video più belli e significativi delle nostre esibizioni ma, al momento, è ancora in costruzione.", 
            "La sezione \"News\" ospiterà tutti gli articoli scritti da noi, nei quali vi racconteremo eventi di recente accadimento o progetti futuri in fase di sviluppo.",
            "\"Eventi\" contiene la lista, in ordine cronologico, degli eventi a cui siamo stati invitati e a cui presto parteciperemo: se il nostro forte suono non dovesse bastare a localizzarci, lì saprete davvero dove trovarci!",
            "Infine, vi lasciamo i nostri \"Contatti\" per scriverci, ingaggiarci, supportarci o semplicemente seguire le nostre attività. Siamo molto attivi sui social: seguendoci lì, non vi perderete mai le nostre avventure. A presto e buon soffio! ",
        ], 
        imageLink: "assets/media/images/news/2024-11-04/claddagh-old.jpg",
        imageLinkLowRes: "assets/media/images/news/2024-11-04/claddagh-old-low-res.jpg",
        tags: ["celticknot", "pipes", "drums", "news", "website", "bagpipes", "piping", "pipeband"],
        externalLink: "https://lrossi.xyz"
    },
];