import { News } from "src/environments/environment";

export const news: News[] = [
    {
        id: "20250320",
        title: "Storia della Cornamusa - Parte 1",
        author: "Corrado Zambonelli",
        date: "20 marzo 2025",
        contentPreview: "Le origini del nostro strumento! ...",
        paragraphs: [
            "Ciao a tutti.",
            "Sono Corrado, un membro della banda Celtic Knot, e con questa rubrica vorrei condividere con voi alcune cose curiose in cui mi sono imbattuto approfondendo il tema della Scozia, della sua storia, della sua musica e, ovviamente, della cornamusa.",
            "Quando sentiamo o vediamo questo strano strumento, la nostra mente immediatamente va alla Scozia, alle sue lande desolate con paesaggi mozzafiato, ai Kilt ed ai Clan: direi che siamo sulla strada giusta. La cornamusa, infatti è lo strumento \"simbolo\" di questa regione. Ma siamo sicuri che la Bagpipe (così si chiama in inglese) sia proprio originaria di lì?",
            "Gli aerofoni a sacco (così sono definiti gli strumenti che funzionano con riserva d'aria) in effetti sono molto diffusi, soprattutto nell'area che parte dal medio oriente ed arriva sino in Europa e nell'isola britannica: pensate che l'archeologia colloca le origini di questi strumenti negli anni che vanno dal 2800 al 2500 A.C. ma probabilmente anche prima di questo periodo. Baines annota come, molto tempo prima, antichi fluati di canna, legno od osso iniziassero ad essere sostituiti da una serie di tubi ad ancia che da quel momento in poi ebbero uno sviluppo considerevole, culminando nell'\"aulos\" greco e nella \"tibia\" romana.",
            "Ovviamente, a causa del materiale molto deperibile, i reperti sono molto rari: tuttavia nel cimitero reale della città sumera di Ur è stato ritrovato un doppio \"chanter\" che è la canna del canto dello strumento.", 
            "Sempre in quell'area ed in Palestina venivano anche usate due canne, così come vi sono prove dell'esistenza di un \"chanter\" singolo (come nella cornamusa) come si vede in una incisione raffigurante una scimmia che lo suona con entrambe le mani.",
            "In Egitto lo strumento di sviluppa quasi come lo vediamo oggi, ovvero con la presenza di un bordone (la canna appoggiata sulla spalla del \"piper\" cioè colui che suona la cornamusa) che produce una nota fissa di accompagnamento e di un chanter, suonati contemporaneamente dalla stessa persona: questo strumento però era privo di sacca e il suono partiva con il soffio direttamente dalla bocca.",
            "In Egitto si sono ritrovati molti reperti; addirittura, nel 1910, in un sarcofago datato 1800 A.C. sono stati ritrovati un astuccio con 6 ance di canna (l'ancia è il \"cuore\" dello strumento e consiste in due lamelle di canna che vibrano al passaggio dell'aria producendo il suono) ed un set di chanter divergenti con 4 e 3 fori per la modulazione del suono: questi reperti sono oggi visibili al Museo del Cairo.",
            "Gli studiosi sono abbastanza concordi nell'affermare che lo scritto più antico della \"cornamusa\" possa essere individuato in una satira del poeta ateniese Aristofane, che descrive dei suonatori provenienti da Tebe che soffiavano in una pelle di cane munita di chanter di osso.",
            "Con ogni probabilità si trattava della \"cornamusa\" greca, che abbiamo già visto, era chiamata aulos, ma è solo nell'antica Roma che questo tipo di strumento si sviluppa in modo più consistente sino ad arrivare alle \"tibiae utricularis\" che accompagnavano riti religiosi e militari; pare anche che verso la fine dell'ottavo secolo A.C. esistesse il \"collegium tibicinum\" che aveva il compito di gestire tutto ciò che riguardava questi strumenti.",
            "Ora, direi di fermarci qui.",
            "Riprenderò più avanti, continuando questa fantastica storia che, mi auguro, possa appassionare anche voi.",
            "Continuate a seguirci sul nostro sito!",
        ], 
        imageLink: "assets/media/images/news/2025-03-20/20250320.png",
        imageLinkLowRes: "assets/media/images/news/2025-03-20/20250320.png",
        tags: [
            "celticknot", "pipes", "drums", "bagpipes", "piping", "pipeband", 'Storia', 'Cornamusa', 'MusicTradition', 'AncientBagpipes', 'ScottishBagpipes', 'CelticMusic', 'History', 'PipesAndDrums', 'AerofoniASacco'],
        externalLink: {
            title: "",
            link: ""
        }
    },
    {
        id: "20250310",
        title: "International Bagpipe Day",
        author: "Elisa Brescianini",
        date: "10 marzo 2025",
        contentPreview: "Scopriamo cos'è l'International Bagpipe Day! ...",
        paragraphs: [
            "Ogni anno, il 10 marzo, il mondo celebra l'International Bagpipe Day, un'occasione dedicata a uno degli strumenti musicali più antichi e riconoscibili. La cornamusa, con la sua storia millenaria, è un simbolo di molte tradizioni culturali, in particolare in Scozia, Irlanda e altre regioni europee. Il suo suono caratteristico ha attraversato i secoli, accompagnando cerimonie, battaglie ed eventi pubblici.",
            "Strumento a fiato composto da una sacca d'aria, un chanter per la melodia e uno o più bordoni che producono un suono continuo, la cornamusa è presente in molte culture del mondo. Le prime testimonianze risalgono all'Antica Roma e all'Egitto, ma le sue raffigurazioni diventano frequenti nell'arte dell'Europa occidentale a partire dal II millennio. Tra il XV e il XVI secolo, le cornamuse erano spesso rappresentate negli intagli lignei dei cori delle chiese europee, segno della loro popolarità.",
            "Nel Medioevo, la cornamusa scozzese assunse la forma che oggi conosciamo, venendo impiegata nelle battaglie e nei cortei cerimoniali. Dal XVI secolo, divenne sempre più radicata nella cultura scozzese, fino a diventare un elemento fondamentale di eventi tradizionali come la Burns Night e gli Highland Games. Tuttavia, strumenti simili esistono in molte altre culture: dalla gaita galiziana in Spagna alle cornamuse balcaniche, fino alla musette francese.",
            "L'International Bagpipe Day è stato istituito da Andy Letcher e Cassandre Balosso-Bardin, in collaborazione con l'International Bagpipe Organization e la Bagpipe Society, quest'ultima attiva dal 1986 per promuovere e preservare la pratica delle cornamuse. L'idea nacque con il primo Convegno Internazionale sulla Cornamusa, che riunì musicisti e costruttori di strumenti da tutta Europa e diede ufficialmente il via alla celebrazione globale.",
            "Oggi, la giornata viene festeggiata in tutto il mondo con concerti e incontri di suonatori in città come Glasgow, Harvard, Minsk, Haninge, Iran e Nigeria. Ogni anno, migliaia di musicisti si riuniscono per condividere la propria passione e mantenere viva una tradizione musicale antica, che continua ad affascinare e unire persone di culture diverse.",
            "La foto che vedete è stata scattata da William Galetti, degli Scozzesi di Offida, il 9 marzo 2024, appena prima di entrare nel campo dello Stadio Olimpico di Roma per suonare \'Flower of Scotland\' per la nazionale scozzese di rugby, prima della partita Italia-Scozia. Un momento memorabile in cui abbiamo suonato accanto a City of Rome Pipe Band, Heart of Italy Pipe Band, Veneto Piping School e Scozzesi di Offida, guidati dalla Jedburgh Pipe Band."], 
        imageLink: "assets/media/images/news/2025-03-10/20250310.png",
        imageLinkLowRes: "assets/media/images/news/2025-03-10/20250310.png",
        tags: [
            "celticknot", "pipes", "drums", "bagpipes", "piping", "pipeband", 'InternationalBagpipeDay', 'Cornamusa', 'MusicTradition', 'ScottishBagpipes', 'CelticMusic', 'HighlandGames', 'Bagpiper', 'PipesAndDrums', 'FolkMusic'],
        externalLink: {
            title: "Fonte",
            link: "https://nationaltoday.com/international-bagpipe-day/"
        }
    },
    {
        id: "20241104",
        title: "Abbiamo un nuovo sito!",
        author: "Lorenzo Rossi",
        date: "4 novembre 2024",
        contentPreview: "Finalmente siamo online con il nuovo sito! ...",
        paragraphs: [
            "Finalmente siamo online con il nuovo sito! E ve lo diciamo usando come scusa questa bellissima nostra foto del 2022 al Claddagh Fest di Ameno (NO).",
            "La Home mostra un'anteprima dei contenuti che si possono trovare nelle altre pagine e un breve video che ci ritrae a Celtica, un importante festival di arte e cultura celtica della Valle d'Aosta. ",
            "Nella sezione \"Banda\" potrete dare un nome ed un cognome ai volti che vedete gonfiarsi e sbuffare arte incessantemente durante i nostri servizi, e anche conoscere la storia della nascita della nostra banda. ",
            "La \"Galleria\" raccoglierà le foto ed i video più belli e significativi delle nostre esibizioni ma, al momento, è ancora in costruzione.", 
            "La sezione \"News\" ospiterà tutti gli articoli scritti da noi, nei quali vi racconteremo eventi di recente accadimento o progetti futuri in fase di sviluppo.",
            "\"Eventi\" contiene la lista, in ordine cronologico, degli eventi a cui siamo stati invitati e a cui presto parteciperemo: se il nostro forte suono non dovesse bastare a localizzarci, lì saprete davvero dove trovarci!",
            "Infine, vi lasciamo i nostri \"Contatti\" per scriverci, ingaggiarci, supportarci o semplicemente seguire le nostre attività. Siamo molto attivi sui social: seguendoci lì, non vi perderete mai le nostre avventure. A presto e buon soffio! ",
        ], 
        imageLink: "assets/media/images/news/2024-11-04/claddagh-old.jpg",
        imageLinkLowRes: "assets/media/images/news/2024-11-04/claddagh-old-low-res.jpg",
        tags: ["celticknot", "pipes", "drums", "news", "website", "bagpipes", "piping", "pipeband"],
        externalLink: {
            title: 'lrossi.xyz',
            link: "https://lrossi.xyz"
        }
    },
    
];