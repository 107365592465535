<div style="text-align: justify;">
    <h1 class="m-5 text-center">Banda</h1>
    <div class="text-center">
        <img src="assets/media/images/pipe-band/pipe-band.jpg" class="img-fluid center shadow" alt="La nostra pipe band">
    </div>
    <div class="m-5 text-center">
        <h4>Pipe major</h4>
        <p>Luca Salvi Bolis</p>
    </div>

    <div class="m-5 text-center">
        <h4>Pipers</h4>
        <p class="m-0">
            Daniele Ciamei, Guy Curtois, Andrea Martinoli, Dario Noris, Giancarlo Percopo, Luca Stocco,  Lorenzo Rossi, Mattia Rossi, Umberto Rossi, Corrado Zambonelli, Pierluigi Zuin
        </p>
    </div>

    <div class="m-5 text-center">
        <h4>Drummers</h4>
        <p class="m-0">Simone Colzani - snare drum</p>
        <p class="m-0">Luana Parrello - tenor drum</p>
    </div>

    <hr>

    <h2 class="m-5">La nostra storia</h2>
    <p class="m-5"><b>Celtic Knot Pipes and Drums</b> è una formazione di cornamuse e percussioni tradizionali scozzesi che raduna musicisti provenienti da diverse esperienze musicali.
    </p>
    <p class="m-5">
        Sebbene di recente formazione, ufficialmente fondata nell’estate 2020, la storia musicale dei suoi componenti ha radici molto più profonde: alle spalle ci sono decenni di esperienza di concerti di musica tradizionale, affiancando sul palco artisti di statura internazionale.
    </p>
    <p class="m-5">
        Nel progetto Celtic Knot la cornamusa delle Highlands è radicata sia nella tradizione che proiettata nella contemporaneità. Tanto che ai grandi classici vengono affiancati brani di autori moderni, nonché composizioni originali.
    </p>
    <div class="d-flex center justify-content-center">
        <img src="assets/media/logos/celtic-knot-logo.png" class="img-fluid text-center" alt="Logo Celtic Knot" style="max-width: 20vw;">
    </div>
    <p class="m-5">
        Il logo del gruppo consiste in un <b>nodo celtico</b>, elemento tipico di questa antica cultura. La sua caratteristica è quella di essere un nastro che non
        ha un inizio e non ha una fine, a rappresentare la continuità della Vita, nell’unione di tutte le sue manifestazioni.
    </p>
    <p class="m-5">
        <b>Unità e forza</b> (Unity and Strength!) sono il significato profondo di questo nodo celtico (<b>Celtic Knot</b>, appunto) e infatti ne hanno fatto il loro motto. Un motto che è lì a ricordare per sempre le circostanze in cui è nato il gruppo: tra un lockdown e una quarantena, nel pieno della pandemia di Covid che ha attraversato il mondo, stroncato vite umane e colpito duramente la musica in ogni dove. Questa è la loro risposta: la forza dei legami tra le persone, la potenza dell’arte e della musica che attraverso i secoli e i millenni diffonde l’energia vitale, sempre e comunque.
    </p>
</div>