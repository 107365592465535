<div class="container-fluid">
    <div class="d-flex justify-content-around">
        <img src="{{news.imageLink}}" class="img-fluid shadow" alt="{{news.contentPreview}}">
    </div>
    <h1 class="m-5 text-center">{{news.title}}</h1>

    <p *ngFor="let paragraph of news.paragraphs" class="px-5" style="text-align: justify;">{{paragraph}}</p>

    <p *ngIf="news.externalLink != '' || news.externalLink != undefined" class="m-5">
        <a href="{{news.externalLink.link}}" target="_blank">{{news.externalLink.title}}</a>
    </p>

    <p *ngIf="news.tags.length != 0" class="m-5">
        Tag:&nbsp;<span *ngFor="let tag of news.tags"><span class="badge rounded-pill text-bg-secondary">#{{tag}}</span>&nbsp;&nbsp;</span>
    </p>

    <p class="m-5 text-end">
        Pubblicato il <b>{{news.date}}</b>, scritto da <b>{{news.author}}</b>
    </p>
</div>