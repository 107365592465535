<div style="text-align: justify;">
    <h1 class="m-5 text-center">Eventi</h1>
    <hr>
    <h2 class="m-5">Hire us!</h2>
    <p class="m-5">Sei un promoter e vuoi il rombo delle nostre cornamuse al tuo evento? Sei un privato e vuoi portare un po' di Scozia nella tua cerimonia? La nostra musica ha solcato i palchi dei festival celtici più importanti d'Italia, ma ha anche animato eventi come feste private, cerimonie e sagre.</p>
    <p class="m-5">Qualunque sia il tuo evento, <a [routerLink]="['../contacts']">contattaci</a>: decideremo insieme la soluzione migliore per te tra diverse opzioni tra cui il singolo piper, l’ensemble ridotto e l’intera pipe band.</p>

    <div *ngIf="loading" 
     style="display: flex; justify-content: center; align-items: center; height: 100%;">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

    <div
    *ngIf="!loading && upcoming.length > 0">
        <hr>
        <h2 class="m-5">Prossimi eventi</h2>
        <!--Next events list-->
        <div *ngFor="let event of upcoming" class="card m-5 border-0 rounded-0 shadow">
            <div class="row g-0">
                <!--se non è stata creata la data, c'è un elemento data/ora-->
                <div *ngIf="event.start.date == undefined" class="card-date col-md-2 text-center d-flex justify-content-center align-items-center fs-3">
                    {{formatDate(separateDateAndTime(event.start.dateTime)['date']).toUpperCase()}}<br>ore {{formatTime((event.start.dateTime))}}
                </div>

                <!--altrimenti c'è un elemento solo data-->
                <div *ngIf="event.start.dateTime == undefined" class="card-date  col-md-3 d-flex justify-content-center align-items-center fs-3">
                    {{formatDate(event.start.date).toUpperCase()}}
                </div>

                <div class="card border-0 rounded-0 col-md-10">
                    <div class="card-header d-flex align-items-center">
                        <h5 class="card-title">{{event.summary}}</h5>
                    </div>
                    <div class="card-body rounded-0">
                        <!--se c'è un allegato, lo piazzo qui-->
                        <!--img *ngIf="event.attachments != undefined" src='https://drive.usercontent.google.com/download?id={{event.attachments[0].fileId}}' allow="autoplay"-->
                        <p *ngIf="event.description != undefined" class="card-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                            </svg>
                            &nbsp;
                            {{event.description}}
                        </p>

                        <!--se c'è un allegato, permetto di scaricarlo qui-->
                        <p *ngIf="event.attachments != undefined">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                                <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                            </svg>
                            &nbsp;
                            <a href='https://drive.google.com/file/d/{{event.attachments[0].fileId}}/preview' allow="autoplay" target="_blank">
                                Scarica locandina
                            </a>
                        </p>

                        <p *ngIf="event.links!= undefined && event.links[0] != ''">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"/>
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
                            </svg>                        
                            &nbsp;
                            <a href="{{event.links[0]}}" target="_blank">Link esterno</a>
                        </p>
                    </div>
                    <div class="card-footer">
                        <p *ngIf="event.location != undefined" class="card-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                            </svg>
                            &nbsp;<a href="{{gMapsLink(event.location)}}" target="_blank">{{event.location}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div
    *ngIf="!loading && past.length > 0">
        <hr>
        <h2 class="m-5">Eventi passati</h2>    
        <!--Past events list-->
        <div *ngFor="let event of past" class="card m-5 border-0 rounded-0 shadow">
            <div class="row g-0">
                <!--se non è stata creata la data, c'è un elemento data/ora-->
                <div *ngIf="event.start.date == undefined" class="card-date col-md-2 text-center d-flex justify-content-center align-items-center fs-3">
                    {{formatDate(separateDateAndTime(event.start.dateTime)['date']).toUpperCase()}}<br>ore {{formatTime((event.start.dateTime))}}
                </div>

                <!--altrimenti c'è un elemento solo data-->
                <div *ngIf="event.start.dateTime == undefined" class="card-date  col-md-3 d-flex justify-content-center align-items-center fs-3">
                    {{formatDate(event.start.date).toUpperCase()}}
                </div>

                <div class="col-md-10 rounded-0">
                    <div class="card-header d-flex align-items-center">
                        <h5 class="card-title">{{event.summary}}</h5>
                    </div>
                    <div class="card-body">
                        <!--se c'è un allegato, lo piazzo qui-->
                        <!--img *ngIf="event.attachments != undefined" src='https://drive.usercontent.google.com/download?id={{event.attachments[0].fileId}}' allow="autoplay"-->
                        <p *ngIf="event.description != undefined" class="card-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                            </svg>
                            &nbsp;
                            {{event.description}}
                        </p>

                        <!--se c'è un allegato, permetto di scaricarlo qui-->
                        <p *ngIf="event.attachments != undefined">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16">
                                <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                            </svg>
                            &nbsp;
                            <a href='https://drive.google.com/file/d/{{event.attachments[0].fileId}}/preview' allow="autoplay" target="_blank">
                                Scarica locandina
                            </a>
                        </p>

                        <p *ngIf="event.links!= undefined && event.links[0] != ''">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                                <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1 1 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4 4 0 0 1-.128-1.287z"/>
                                <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243z"/>
                            </svg>                        
                            &nbsp;
                            <a href="{{event.links[0]}}" target="_blank">Link esterno</a>
                        </p>
                    </div>
                    <div class="card-footer">
                        <p *ngIf="event.location != undefined" class="card-text">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
                            </svg>
                            &nbsp;<a href="{{gMapsLink(event.location)}}" target="_blank">{{event.location}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>