import { Component, NgModule, OnInit } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { GoogleCalendarService } from '../google-calendar.service';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './events.component.html',
  styleUrl: './events.component.css'
})
export class EventsComponent implements OnInit {

  events: any [] = [];
  upcoming: any [] = [];
  past: any[] = [];
  today: Date = new Date();

  loading: boolean = false;
  constructor(
    private gCalendarService: GoogleCalendarService,
    private viewportScroller: ViewportScroller
  ) {

  }

  ngOnInit() {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.loading = true;
    this.gCalendarService.getCalendarEventsPromise().subscribe(
      res => {
        let response: any = res;
        this.events = response.items;
        //console.log(this.events);
        this.filterEvents(this.events)
        this.loading = false;
        //console.log(this.events)
      }
    )
  }
  
  separateDateAndTime(datetime: string): { date: string, time: string } {
    // Separare la data e l'ora usando 'T' come delimitatore
    const date = datetime.split('T')[0];
    const time = datetime.split('T')[1];

    // Restituire un oggetto con la data e l'ora separati
    return {
      date,
      time: time.replace('Z', '')
    };
  }

  formatDate(dateString: string) {
    const date = new Date(dateString); // Convert string to Date object
    
    // Format with Italian locale, abbreviated month, and numeric day/year
    return new Intl.DateTimeFormat('it-IT', {
      day: '2-digit',
      month: 'short', // Abbreviated month
      year: 'numeric'
    }).format(date);
  }

  formatTime(datetimeString: string) {
    return datetimeString.slice(11, 16);
  }

  gMapsLink(address: string) {
    const encodedAddress = encodeURIComponent(address);
    if(this.isMobileDevice()) {
      return`https://www.google.com/maps/search/?query=${encodedAddress}`;
    }
    else {
      return`https://www.google.com/maps/search/?q=${encodedAddress}`;
    }
  }

  isMobileDevice(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}

  filterEvents(events: any[]) {
    let todayYyyyMmDd = this.separateDateAndTime(this.today.toISOString())['date']; 
    for(let i = 0; i < events.length; ++i) {
      if(this.separateDateAndTime(events[i].start.dateTime)['date'] >= todayYyyyMmDd) {
        this.upcoming.push(events[i]);
      }
      else {
        this.past.push(events[i]);
      }
    }
    //console.log(this.upcoming)
  }
}
