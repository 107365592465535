import { Injectable } from '@angular/core';
import { News } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  sharedSingleNews!: News;
  constructor() { }

  setSharedSingleNews(news: News) {
    this.sharedSingleNews = news;
  }

  getSharedSingleNews() {
    return this.sharedSingleNews;
  }
}
