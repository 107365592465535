<img src="{{news.imageLink}}" class="img-fluid p-5" alt="{{news.contentPreview}}">
<h1 class="m-5 text-center">{{news.title}}</h1>

<p *ngFor="let paragraph of news.paragraphs" class="" style="margin-left: 50px; margin-right: 50px; text-align: justify;">{{paragraph}}</p>

<p *ngIf="news.externalLink != '' || news.externalLink != undefined" class="m-5">
    <a href="{{news.externalLink}}" target="_blank">Link esterno</a>
</p>

<p *ngIf="news.tags.length != 0" class="m-5">
    Tag:&nbsp;<span *ngFor="let tag of news.tags"><span class="badge rounded-pill text-bg-secondary">#{{tag}}</span>&nbsp;&nbsp;</span>
</p>

<p class="m-5 text-end">
    Pubblicato il <b>{{news.date}}</b>, scritto da <b>{{news.author}}</b>
</p>