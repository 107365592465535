import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-pipe-band',
  standalone: true,
  imports: [],
  templateUrl: './pipe-band.component.html',
  styleUrl: './pipe-band.component.css'
})
export class PipeBandComponent implements OnInit{
  constructor(
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {

  }
  
  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.shared.updateActiveLink();
  }
}
