import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { News } from 'src/environments/environment';
import { CommonModule, ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-news-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './news-detail.component.html',
  styleUrl: './news-detail.component.css'
})
export class NewsDetailComponent implements OnInit{
  news!: News;

  constructor(
    private sharedService: SharedService,
    private viewportScroller: ViewportScroller
  ) {
    this.news = this.sharedService.getSharedSingleNews();
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

}
