<!--
=========================================================================================
          CAROUSEL
=========================================================================================
-->
<div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-bs-theme="dark" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="assets/media/images/carousel/celtica-skyway-16-9.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/media/images/carousel/celtica-aosta-16-9.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
    <div class="carousel-item">
      <img src="assets/media/images/carousel/celtica-aosta-2-16-9.png" class="d-block w-100" alt="...">
      <div class="carousel-caption d-none d-md-block">
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Precedente</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Successiva</span>
  </button>
</div>


<img src="assets/media/logos/celtic-knot-logo-w.png" class="img-fluid p-5" alt="Celtic Knot Pipes and Drums full logo">

<hr>

<h1 class="m-5 text-center">Benvenuti sul nostro sito!</h1>
<p class="m-5 text-center">Siamo <b>Celtic Knot Pipes and Drums</b>, banda di cornamuse e percussioni scozzesi nata in Lombardia nel 2020.</p>
 
  
  <!-- Marketing messaging and featurettes
  ================================================== -->
  <!-- Wrap the rest of the page in another container to center all the content. -->
  
  <div class="container marketing mt-5 mb-5">
    <!-- Three columns of text below the carousel -->
    <div class="d-flex flex-wrap justify-content-evenly">
      <div class="col-lg-4 d-flex flex-column justify-content-center text-center m-3">
        <img class="rounded-circle shadow-lg img-fluid" src="assets/media/images/home/banda.jpg" alt="Banda">
        <h2 class="fw-normal m-3">Banda</h2>
        <p>Scopri la storia ed i componenti della nostra banda.</p>
        <p><a class="btn btn-secondary" [routerLink]="['pipe-band']">Vai a Banda &raquo;</a></p>
      </div><!-- /.col-lg-4 -->

      <div class="col-lg-4 d-flex flex-column justify-content-center text-center m-3">
        <img class="rounded-circle shadow-lg img-fluid" src="assets/media/images/home/details.jpg" alt="Galleria">
        <h2 class="fw-normal m-3">Galleria</h2>
        <p>Alcuni scatti che ci ritraggono durante le nostre esibizioni.</p>
        <p><a class="btn btn-secondary" [routerLink]="['gallery']">Vai a Galleria &raquo;</a></p>
      </div><!-- /.col-lg-4 -->

      <div class="col-lg-4 d-flex flex-column justify-content-center text-center m-3">
        <img class="rounded-circle shadow-lg img-fluid" src="assets/media/images/home/news.jpg" alt="News">
        <h2 class="fw-normal m-3">News</h2>
        <p>Rimani aggiornato sulle avventure musicali della nostra banda.</p>
        <p><a class="btn btn-secondary" [routerLink]="['news']">Vai a News &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
      
      <div class="col-lg-4 d-flex flex-column justify-content-center text-center m-3">
        <img class="rounded-circle shadow-lg img-fluid" src="assets/media/images/home/202407-celtica.jpg" alt="Eventi">
        <h2 class="fw-normal m-3">Eventi</h2>
        <p>Consulta il nostro calendario per scoprire dove suoneremo.</p>
        <p><a class="btn btn-secondary" [routerLink]="['events']">Vai a Eventi &raquo;</a></p>
      </div><!-- /.col-lg-4 -->

      <div class="col-lg-4 d-flex flex-column justify-content-center text-center m-3">
        <img class="rounded-circle shadow-lg img-fluid" src="assets/media/images/home/kilt-pin.jpg" alt="Contatti">
        <h2 class="fw-normal m-3">Contatti</h2>
        <p>Vuoi farci qualche domanda o invitarci al tuo evento?</p>
        <p><a class="btn btn-secondary" [routerLink]="['contacts']">Vai a Contatti &raquo;</a></p>
      </div><!-- /.col-lg-4 -->
    </div><!-- /.row -->
  </div>

<hr>
<h1 class="m-5 text-center">Seguici su YouTube</h1>

<div class="m-5">
    <div class="ratio ratio-16x9 p-5 mt-5 mb-5">
      <iframe src="https://www.youtube.com/embed/XfoWpshv3tY?si=6sD_tMxxRxSAA-qf" title="Celtic Knot Pipes and Drums parade in Aosta" allowfullscreen></iframe>
    </div>
</div>

<p class="m-5 text-center"><a href="https://www.youtube.com/@CelticKnotPipesandDrums" target="_blank" class="btn btn-secondary">Vai al nostro canale &raquo;</a></p>