import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleCalendarService {

  calendarId: string = "b5973f731e14d7013aeaca03fbe8af2634591a494f1aa733697f804df7303406@group.calendar.google.com";
  calendarPath: string = "https://www.googleapis.com/calendar/v3/calendars/"
  //TODO: hide this
  apiKey: string = "AIzaSyCyabhJTj73B4KVxCo2MLi__7DJuJ09H54";
  constructor(
    private http: HttpClient
  ) { }

  getCalendarEventsPromise() {
    let path = this.calendarPath + this.calendarId + "/events?key=" + this.apiKey + "&singleEvents=true&orderBy=startTime";
    //console.log(path);
    return this.http.get<String[]>(
      path,
      {
        responseType: 'json',
      }
    )
  }
}
