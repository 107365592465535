import { Component, NgModule, OnInit } from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import { GoogleCalendarService } from '../google-calendar.service';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-events',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './events.component.html',
  styleUrl: './events.component.css'
})
export class EventsComponent implements OnInit {

  events: any [] = [];
  upcoming: any [] = [];
  past: any[] = [];
  today: Date = new Date();

  loading: boolean = false;
  constructor(
    private gCalendarService: GoogleCalendarService,
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {

  }

  ngOnInit() {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.loading = true;
    this.shared.updateActiveLink();
    this.gCalendarService.getCalendarEventsPromise().subscribe(
      res => {
        //console.log(res);
        let response: any = res;
        this.events = response.items;
        //console.log(this.events);
        this.filterEvents(this.events)
        this.loading = false;
        //console.log(this.events)
      }
    )
  }
  
  separateDateAndTime(datetime: string): { date: string, time: string } {
    // Separare la data e l'ora usando 'T' come delimitatore
    const date = datetime.split('T')[0];
    const time = datetime.split('T')[1];

    // Restituire un oggetto con la data e l'ora separati
    return {
      date,
      time: time.replace('Z', '')
    };
  }

  formatDate(dateString: string) {
    const date = new Date(dateString); // Convert string to Date object
    
    // Format with Italian locale, abbreviated month, and numeric day/year
    return new Intl.DateTimeFormat('it-IT', {
      day: '2-digit',
      month: 'short', // Abbreviated month
      year: 'numeric'
    }).format(date);
  }

  formatTime(datetimeString: string) {
    return datetimeString.slice(11, 16);
  }

  gMapsLink(address: string) {
    const encodedAddress = encodeURIComponent(address);
    if(this.isMobileDevice()) {
      return`https://www.google.com/maps/search/?query=${encodedAddress}`;
    }
    else {
      return`https://www.google.com/maps/search/?q=${encodedAddress}`;
    }
  }

  isMobileDevice(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
}

  filterEvents(events: any[]) {
    let todayYyyyMmDd = this.separateDateAndTime(this.today.toISOString())['date']; 
    for(let i = 0; i < events.length; ++i) {
      events[i].links = this.extractLinks(events[i].description)
      events[i].description = this.removeLinks(events[i].description);
      if(events[i].links.length == 0){
        events[i].links = undefined
      }
      if(this.separateDateAndTime(events[i].start.dateTime)['date'] >= todayYyyyMmDd) {
        this.upcoming.push(events[i]);
      }
      else {
        this.past.unshift(events[i]);
      }
    }

    console.log("next")
    console.log(this.upcoming)
    console.log("past")
    console.log(this.past)
  }

  extractLinks(text: string): string[] {
    //console.log(text)
    if (!text) return [];
  
    // Regex per trovare URL
    const urlRegex = /(https?:\/\/[^\s]+)/g;
  
    // Usa il metodo match per trovare tutti i link nel testo
    const matches = text.match(urlRegex);
  
    // Se ci sono corrispondenze, restituisci l'array, altrimenti un array vuoto
    return matches || [];
  }

  getDescriptionWithLinks(description: string): string {
    if (!description) return '';

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return description.replace(
      urlRegex,
      (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );
  }

  removeLinks(text: string): string {
    if (!text) return '';
  
    // Regex per trovare URL
    const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
  
    // Sostituisce tutti i link con una stringa vuota
    return text.replace(urlRegex, '');
  }
}
