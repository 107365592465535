import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';
import { News } from 'src/environments/environment';
import { CommonModule, ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { news } from '../news/news-list';

@Component({
  selector: 'app-news-detail',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './news-detail.component.html',
  styleUrl: './news-detail.component.css'
})
export class NewsDetailComponent implements OnInit{
  news!: News;
  currentUrl!: string;

  constructor(
    private shared: SharedService,
    private viewportScroller: ViewportScroller,
    private router: Router
  ) {
    //console.log(this.router.url.split('/')[2])
    if(this.news == undefined) {
      this.currentUrl = this.router.url.split('/')[2];
      //console.log(this.currentUrl);
      for(let i = 0; i < news.length; ++i) {
        if(this.currentUrl == news[i].id) {
          //console.log(news[i]);
          this.shared.setSharedSingleNews(news[i]);
        }
        //TODO: se l'articolo non è presente, reindirizza alla 404
      }
    }
    this.news = this.shared.getSharedSingleNews();
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.shared.updateActiveLink();
  }

}
