import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent implements OnInit{
  constructor(
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {
    
  }
  
  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.shared.updateActiveLink();
  }
}
