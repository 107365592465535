import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{

  title = 'Celtic Knot Pipes and Drums';
  year: number = new Date().getFullYear();
  isLandingPage: boolean = true;
  loading: boolean = true;
  
  constructor(private router: Router,
    public shared: SharedService
  ) {
    this.printLogo();
  }
  
  ngOnInit(): void {
    if (document.readyState === 'complete') {
      this.onPageLoad();
    } else {
      window.addEventListener('load', () => this.onPageLoad());
    }
  }

  onPageLoad(): void {
    setTimeout(() => {
      const loader = document.getElementById('loader');
      if (loader) {
        loader.classList.add('fade-out');
  
        // Attendi la fine della transizione prima di nascondere l'elemento
        loader.addEventListener('transitionend', () => this.loading = false, { once: true });
      }
    }, 1000);
  }

  toggleMenu() {
    const navButton = document.querySelector('#navButton') as HTMLButtonElement;
    const navbar = document.querySelector('#navbar') as HTMLDivElement;
    if(navbar.getAttribute('class')!.includes("show")) {
      navbar.setAttribute("class", navbar.getAttribute('class')?.replace("show", "")!);
    }
  }


  printLogo() {
    console.log(`
                          .~7JY55~  ~PYJ7!^.                          
                      :!YB&@@@@@@Y  Y@@@@@@&B57^                      
                   .7G&@@@@&B&@@@5  5@@@@B&@@@@@B?.                   
                 :Y&@@@&GJ~.:&@@@J  J@@@&:.~JG@@@@&J.                 
                7&@@@#J:    Y@@@@^  ^@@@@J    :J&@@@B^                
               :5GGP5!.    ^&@@@5    P@@@@~    .~P@@@&^               
           ^?PB&&@@@@@&BP!.B@@@#:    :#@@@Y:75B&&7P@@@G?P?^           
        .7B@@@@@#BBGB&@@G?B@@@#^      ^BPYG&@@@@@?7@@@#J@@@B7.        
       !#@@@@G7JPPB5 .^7Y&@@@G~        !B@@@&GJ~. G@@@G^G@@@@#!       
     .5@@@@P^  !@@@@5 7&@@@&Y5&Y      Y@@@@P?PB?.7&@@@~  ^5@@@@5.     
     G@@@&!     !&@@@#YYB#J^B@@@5    5@@@@?^#@@@&GYYG!     !&@@@G     
    Y@@@&~  .:~?YJP&@@@#PJ: 7@@@@7  7@@&#7 ^?5G&@@@&B5?~:.  ~&@@@Y    
   ^@@@@&GB#&@@@@@B?JB@@@@@&?Y@@@#.^YPPPPG#@@&BYJG&@@@@@@&#BG&@@@@^   
   Y@@@@@@@@@&#G5?~.  ^?PB&@@~&@@@7G@@@@@@@@#PJ~  .~?5G#&@@@@@@@@@Y   
   :~~!!!~~^:.            :~7.PBBB7JBGGPJ7~:            .:^~~!!!~~:   
   ^!!!7!!~^^:.          .:~7YPGGB?7#B#G:?!^.          .:^~~!777!!^   
   J@@@@@@@@@&#G5J~:  ~YG#@@@@@@@@B?@@@&!@@@#GJ~. .!J5B#&@@@@@@@@@Y   
   :&@@@&GB#&@@@@@@&GY5G&@@#PPP555^:&@@@Y?#&@@@@#Y?B@@@@@&#GP&@@@@^   
    J@@@&~  .:~7YG&@@@&B5?: 7&&@@?  ?@@@@7 :?P#@@@&PJJ?!^:  ~&@@@5    
     P@@@&7     !G5YG&@@@#^?@@@@Y    Y@@@B^Y#B5YB@@@&!     7&@@@P     
      5@@@@P^  ~@@@@7.?BPJG@@@@J      J&YY&@@@#! 5@@@@!  ^P@@@@Y      
       !B@@@@G^B@@@G.:~JB@@@@G!        ~G@@@&Y?~..5GPPJ?G@@@@B!       
         7G@@@7#@@@?J@@@@@&G5GB^      ^#@@@B?B@@&#BBB#@@@@@G7         
           :7P!G@@@G~&#GY!:Y@@@#:    :#@@@G.!5B#&@@@&&&B57:           
               ~&@@@G~     :#@@@P    P@@@#:     !5PPPY:               
                ~B@@@&Y^    7@@@@^  ^@@@@7    ^Y&@@@B^                
                 .J#@@@@BJ!::&@@@J  J@@@&::!YB@@@@#?                  
                   .7G&@@@@&#@@@@5  5@@@@#@@@@@&G7.                   
                      :7YG&@@@@@@Y  Y@@@@@&#GY!:                      
                          .^~7?Y5^  ~5J7!^:.                    `)
  }


    
}
