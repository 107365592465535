import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.css'
})
export class NotFoundComponent implements OnInit{
  constructor(
    private viewportScroller: ViewportScroller
  ) {

  }
  
  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
