import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-contacts',
  standalone: true,
  imports: [],
  templateUrl: './contacts.component.html',
  styleUrl: './contacts.component.css'
})
export class ContactsComponent implements OnInit {
  constructor(
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {
    
  }
  
  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
    this.shared.updateActiveLink();
  }
}
