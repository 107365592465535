import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';
import { albums } from './albums';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrl: './gallery.component.css'
})
export class GalleryComponent implements OnInit{
  images: any[] = [];

  celtica2024: any[] = [];
  albino2024: any[] = [];

  currentImageIndex = 0;
  isAnimating = false;

  constructor(
    private viewportScroller: ViewportScroller,
    private shared: SharedService
  ) {
    
  }

  ngOnInit(): void {
    this.celtica2024 = albums.celtica2024;
    this.albino2024 = albums.albino20241201;

    //TODO: trovare un metodo alternativo allo schifo delle prossime due righe
    this.openModal(1, 'celtica2024Modal');
    this.closeModal('celtica2024Modal');

    this.viewportScroller.scrollToPosition([0, 0]);
    this.shared.updateActiveLink();
  }  

  openModal(index: number, modalId: string): void {
    this.currentImageIndex = index;
    switch(modalId) {
      case 'celtica2024Modal':
        this.images = this.celtica2024;
        break;
      case 'albino2024Modal':
        this.images = this.albino2024;
        break;
      default:
        break;
    }
    const modal = document.getElementById(modalId) as HTMLElement;
    modal.style.display = 'block';
    modal.classList.add('show');
  }

  closeModal(modalId: string): void {
    const modal = document.getElementById(modalId) as HTMLElement;
    modal.style.display = 'none';
    modal.classList.remove('show');
  }

  nextImage(): void {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }

  previousImage(): void {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.images.length) % this.images.length;
  }

  changeImage(newIndex: number): void {
    if (this.isAnimating)
      return;
    this.isAnimating = true;
    setTimeout(() => {
      this.currentImageIndex = newIndex;
      this.isAnimating = false;
    }, 300); 
  }
}
