import { Component, OnInit } from '@angular/core';
import { news } from './news-list';
import { News } from 'src/environments/environment';
import { CommonModule, ViewportScroller } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-news',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './news.component.html',
  styleUrl: './news.component.css'
})
export class NewsComponent implements OnInit{
  news: News[] = news;

  constructor(
    private sharedService: SharedService,
    private viewportScroller: ViewportScroller
  ) {
    
  }

  ngOnInit(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  setSharedSingleNews(news: News) {
    this.sharedService.setSharedSingleNews(news);
  }  
}
